import axios from 'axios';
import { API, API_HOST } from 'config/api';
import { getRefreshToken, getToken, setToken } from './authenticationService';

// Add a request interceptor
axios.interceptors.request.use(
  function(config) {
    // Get token
    const token = getToken();
    // Do something before request is sent
    config.headers.Authorization = `Bearer ${token}`;

    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  },
);
// Add a response interceptor
axios.interceptors.response.use(
  response => response,
  error => {
    // Reject promise if usual error
    if (error.response.status !== 401) {
      return Promise.reject(error);
    }
    if (getRefreshToken()) {
      const url = API_HOST + API.auth.refreshToken;
      axios
        .create()
        .post(url, { refresh_token: getRefreshToken() })
        .then(
          response => {
            //get token
            const token = response.data.token;
            setToken(token);
            //reloads the page after magic is done *_*
            window.location.reload();
          },
          error => {
            localStorage.clear();
            window.location = '/';
          },
        );
    }
    return Promise.reject(error);
  },
);
const axiosService = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch,
};
export default axiosService;
