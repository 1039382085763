import React, { useState } from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';

import { Menu } from 'antd';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';

const MenuItems = ({ toggleCollapsed }) => {
  const { path } = useRouteMatch();

  var pathName = window.location.pathname.split('/');

  const [openKeys, setOpenKeys] = useState(pathName.length > 2 ? pathName.slice(2, pathName.length) : []);

  const onOpenChange = keys => setOpenKeys([keys.pop()]);

  const items = [
    {
      key: 'actif',
      icon: (
        <NavLink className="menuItem-icon" onClick={toggleCollapsed} to={path}>
          <FeatherIcon icon="home" />
        </NavLink>
      ),
      label: 'Dashboard',
    },
    {
      key: 'employee1',
      icon: (
        <NavLink className="menuItem-icon" onClick={toggleCollapsed} to={`${path}/auto-ecoles`}>
          <FeatherIcon icon="trello" />
        </NavLink>
      ),
      label: ' Auto-Ecoles',
    },
    {
      key: 'employee2',
      icon: (
        <NavLink className="menuItem-icon" onClick={toggleCollapsed} to={`${path}/élèves`}>
          <FeatherIcon icon="users" />
        </NavLink>
      ),
      label: 'Elèves',
    },
    {
      key: 'employee3',
      icon: (
        <NavLink className="menuItem-icon" onClick={toggleCollapsed} to={path}>
          <FeatherIcon icon="layout" />
        </NavLink>
      ),
      label: 'Moniteurs',
    },
  ];

  return (
    <Menu
      onOpenChange={onOpenChange}
      mode="inline"
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
      defaultSelectedKeys={[
        window.location.pathname.split('/').pop() === 'admin' ? 'actif' : window.location.pathname.split('/').pop(),
      ]}
      items={items}
    />
  );
};

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
