import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

import { login } from './authAsyncThunk';

const initialState = {
  login: Cookies.get('loggedIn'),
  isLoading: false,
  error: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logOut: state => {
      state.login = false;
      Cookies.remove('loggedIn');
      Cookies.remove('token');
    },
  },
  extraReducers: builder => {
    builder
      .addCase(login.pending, state => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, state => {
        state.isLoading = false;
        state.login = true;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

// Action creators are generated for each case reducer function
export const { logOut } = authSlice.actions;

export default authSlice.reducer;
