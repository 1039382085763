import { configureStore } from '@reduxjs/toolkit';

// Slices
import auth from './slices/auth/authSlice';
import chart from './slices/chart/chartSlice';
import dashboard from './slices/dashboard/dashboardSlice';
import layout from './slices/layout/layoutSlice';
import drivingSchool from './slices/driving-school/drivingSchoolSlice';

export const store = configureStore({
  reducer: {
    auth,
    chart,
    dashboard,
    drivingSchool,
    layout,
  },
});
