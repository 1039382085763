import { createAsyncThunk } from '@reduxjs/toolkit';

import axiosServices from 'services/axiosService';
import { API, API_HOST } from 'config/api';

export const getNewsBlock = createAsyncThunk('blocks/getNewsBlock', async () => {
  const URL = API_HOST + API.dashboard.newsBlock;

  try {
    const res = await axiosServices.get(URL);
    return {
      newOperators: res.data.operators,
      aioActif: res.data.active,
      aioSock: res.data.inStock,
      active_average: res.data.active_average,
      inStock_average: res.data.inStock_average,
      operator_average: res.data.operator_average,
    };
  } catch (error) {
    return error.message;
  }
});

export const getRecentEditors = createAsyncThunk('editors/getRecentEditors', async () => {
  const URL = API_HOST + API.dashboard.recentEditors;

  try {
    const res = await axiosServices.get(URL);

    let data = res.data.items.map((el, index) => ({ ...el, ...{ key: index } }));

    let tab = [];

    for (let i = 0; i < 5; i++) {
      if (data[i] !== undefined) tab.push(data[i]);
    }

    return { data: tab, totalCount: res.data.total_count };
  } catch (error) {
    return error.message;
  }
});
