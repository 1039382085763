import React from 'react';

import { Col, Row } from 'antd';

import { Aside } from './overview/style';

const AuthLayout = WraperContent => {
  return () => {
    return (
      <Row>
        <Col xxl={8} xl={9} lg={12} md={24} xs={24}>
          <Aside>
            <div className="auth-side-content"></div>
          </Aside>
        </Col>
        <Col xxl={16} xl={14} lg={12} md={24} xs={24}>
          <WraperContent />
        </Col>
      </Row>
    );
  };
};

export default AuthLayout;
