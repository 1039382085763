import { createSlice } from '@reduxjs/toolkit';

import { cashFlowFilterData, cashFlowGetData, performanceFilterData, performanceGetData } from './chartAsyncThunk';

const initialState = {
  cashFlowData: {
    data: null,
    isLoading: false,
    error: false,
  },
  performanceData: {
    data: null,
    isLoading: false,
    error: false,
  },
};

export const chartSlice = createSlice({
  name: 'chart',
  initialState,
  extraReducers: builder => {
    builder
      // cash flow chart
      .addCase(cashFlowGetData.pending, state => {
        state.cashFlowData.isLoading = true;
      })
      .addCase(cashFlowGetData.fulfilled, (state, action) => {
        state.cashFlowData.isLoading = false;
        state.cashFlowData.data = action.payload;
      })
      .addCase(cashFlowGetData.rejected, (state, action) => {
        state.cashFlowData.isLoading = false;
        state.cashFlowData.error = action.error.message;
      })

      .addCase(cashFlowFilterData.pending, state => {
        state.cashFlowData.isLoading = true;
      })
      .addCase(cashFlowFilterData.fulfilled, (state, action) => {
        state.cashFlowData.isLoading = false;
        state.cashFlowData.data = action.payload;
      })
      .addCase(cashFlowFilterData.rejected, (state, action) => {
        state.cashFlowData.isLoading = false;
        state.cashFlowData.error = action.error.message;
      })

      // performance chart

      .addCase(performanceGetData.pending, state => {
        state.performanceData.isLoading = true;
      })
      .addCase(performanceGetData.fulfilled, (state, action) => {
        state.performanceData.isLoading = false;
        state.performanceData.data = action.payload;
      })
      .addCase(performanceGetData.rejected, (state, action) => {
        state.performanceData.isLoading = false;
        state.performanceData.error = action.error.message;
      })

      .addCase(performanceFilterData.pending, state => {
        state.performanceData.isLoading = true;
      })
      .addCase(performanceFilterData.fulfilled, (state, action) => {
        state.performanceData.isLoading = false;
        state.performanceData.data = action.payload;
      })
      .addCase(performanceFilterData.rejected, (state, action) => {
        state.performanceData.isLoading = false;
        state.performanceData.error = action.error.message;
      });
  },
});

export default chartSlice.reducer;
