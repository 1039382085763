import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';

import { API, API_HOST } from 'config/api';
import { setRefreshToken, setToken } from 'services/authenticationService';

export const login = createAsyncThunk('auth/login', async (values, stay) => {
  const URL = API_HOST + API.auth.login;

  axios
    .post(URL, values)
    .then(res => {
      setToken(res.data.access);
      setRefreshToken(res.data.refresh);
      if (stay === true) {
        Cookies.set('loggedIn', true, { expires: 365 });
        Cookies.set('token', res.data.access, { expires: 365 });
      } else {
        Cookies.set('loggedIn', true);
        Cookies.set('token', res.data.refresh);
      }
    })
    .catch(err => err);
});
