import Styled from 'styled-components';

const Aside = Styled.aside`
  width: 100%;
  height: 100vh;
  position: relative;
  background-image: url("${require('assets/image/side-login.png')}");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: left top;
  background-size: contain;
  @media (min-width: 425px) and (max-width: 991px){
    height: 100%;
    width: 100%;
    background-image: url("${require('assets/image/side-login.png')}");
  }
  @media only screen and (max-width: 424px){
    height: 100%;
  }
  .auth-side-content{
    @media only screen and (max-width: 991px){
      h1{
        font-size: 20px;
        margin-bottom: 28px;
      }
    }
    @media only screen and (max-width: 767px){
      h1{
        font-size: 24px;
        margin-bottom: 28px;
      }
    }
  }
`;

const Content = Styled.div`
    padding: 8% 0;
    @media only screen and (max-width: 991px){
      text-align: center;
    }
    @media only screen and (max-width: 767px){
      text-align: center;
    }
    .auth-content-figure{
      @media only screen and (max-width: 1199px){
        max-width: 420px;
      }
      @media only screen and (max-width: 991px){
        max-width: 100%;
      }
    }
`;

const AuthWrapper = Styled.div`
  height: 100%;
  padding: 40px;
  @media only screen and (max-width: 1599px){
    padding: 25px;
  }
  @media only screen and (max-width: 991px){
    text-align: center;
    padding: 5rem 25px;
  }
  .auth-notice{
    text-align: right;
    font-weight: 500;
    color: ${({ theme }) => theme['gray-color']};
    @media only screen and (max-width: 767px){
      text-align: center;
      margin-bottom: 10px;
    }
  }
  button{
    &.btn-signin{
      min-width: 185px;
      background: linear-gradient(274.43deg, #F46A6A 0%, #F49595 100%);
      color: #fff;
      font-sie: 14px!important;
    }
  }
  .auth-contents {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    &__logo {
      width: 250px;
      margin-bottom: 5%;
      padding-bottom: 8%;
    }

    form {
      max-width: 420px;
      
      @media only screen and (min-width: 426px) {
        min-width: 420px;
      }

      h1 {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 45px;
        @media only screen and (max-width: 767px) {
          margin-bottom: 28px;
        }
        input::placeholder {
          color: ${({ theme }) => theme['extra-light-color']};
        }
      }
      .auth-form-action {
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;

        span {
          font-size: 13px;
          color: #9299B8;
        }

        @media only screen and (max-width: 379px) {
          flex-flow: column;
          .forgot-pass-link {
            margin-top: 15px;
          }
        }
      }
    }
  }
`;

export { Aside, Content, AuthWrapper };
