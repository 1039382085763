import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import withAdminLayout from 'layout/withAdminLayout';
import Loader from 'components/loader';

const Dashboard = lazy(() => import('container/dashboard'));
const DrivingSchoolsList = lazy(() => import('container/driving-schools'));
const StudentsList = lazy(() => import('container/students'));
const Admin = () => {
  const { path } = useRouteMatch();

  const fallback = () => {
    return (
      <div className="spin">
        <Spin />
      </div>
    );
  };

  return (
    <>
      <Loader />
      <Switch>
        <Suspense fallback={fallback()}>
          <Route exact path={`${path}`} component={Dashboard} />
          <Route exact path={`${path}/auto-ecoles`} component={DrivingSchoolsList} />
          <Route exact path={`${path}/élèves`} component={StudentsList} />
        </Suspense>
      </Switch>
    </>
  );
};

export default withAdminLayout(Admin);
