import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import { InfoWraper } from './auth-info-style';
import { logOut } from 'redux/slices/auth/authSlice';

const AuthInfo = () => {
  const dispatch = useDispatch();
  const SignOut = e => {
    e.preventDefault();
    dispatch(logOut());
  };
  
  return (
    <InfoWraper style={{ position: 'relative' }}>
      <div className="nav-author">
        <Link
          style={{ display: 'flex', alignItems: 'center' }}
          className="user-dropdwon__bottomAction"
          onClick={SignOut}
          to="#"
        >
          <FeatherIcon style={{ marginRight: '5px' }} icon="log-out" size={16} />
          <span>Déconnexion</span>
        </Link>
      </div>
    </InfoWraper>
  );
};

export default AuthInfo;
