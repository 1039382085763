import { createSlice } from '@reduxjs/toolkit';

import { getNewsBlock, getRecentEditors } from './dashboardAsyncThunks';

const initialState = {
  newsBlock: {
    data: [],
    status: 'idle',
    error: null,
  },
  recentEditors: {
    data: [],
    status: 'idle',
    error: null,
  },
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getNewsBlock.pending, state => {
        state.newsBlock.status = 'loading';
      })
      .addCase(getNewsBlock.fulfilled, (state, action) => {
        state.newsBlock.status = 'succeeded';
        state.newsBlock.data = action.payload;
      })
      .addCase(getNewsBlock.rejected, (state, action) => {
        state.newsBlock.status = 'failed';
        state.newsBlock.error = action.error.message;
      })

      .addCase(getRecentEditors.pending, state => {
        state.recentEditors.status = 'loading';
      })
      .addCase(getRecentEditors.fulfilled, (state, action) => {
        state.recentEditors.status = 'succeeded';
        state.recentEditors.data = action.payload;
      })
      .addCase(getRecentEditors.rejected, (state, action) => {
        state.recentEditors.status = 'failed';
        state.recentEditors.error = action.error.message;
      });
  },
});

export default dashboardSlice.reducer;
