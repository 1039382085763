import { createSlice } from '@reduxjs/toolkit';

import staticData from 'config/config';

const initialState = {
  data: staticData.darkMode,
  rtlData: staticData.rtl,
  topMenu: staticData.topMenu,
};

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    changeLayoutMode: (state, action) => {
      state.data = action.payload;
    },
    changeRtlMode: (state, action) => {
      state.rtlData = action.payload;
    },
    changeMenuMode: (state, action) => {
      state.topMenu = action.payload;
    },
  },
});

export const { changeLayoutMode, changeRtlMode, changeMenuMode } = layoutSlice.actions;

export default layoutSlice.reducer;
