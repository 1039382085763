/**
 * It's suggested to configure the RESTful endpoints in this file
 * so that there is only one source of truth, future update of endpoints
 * could be done from here without refactoring on multiple places throughout the app
 */
const API_HOST = "https://api.myinspire.prod-projet.com/";
const CONTENT = {
  data: 'uploads/images'
}
const API = {
  auth: {
    login: 'auth/login/',
    signUp: 'signup',
    refreshToken: 'token/refresh'
  },
  user: {
    user:   'user/',
    register:   'user/register/',
    congeUser:   'user/congeuser?page=',
    authUser:   'user/authuser?page=',
    futurConge:   'user/futurconge',
    numberUser:   'user/numberuser',
    waitHolidayAuth:   'user/waitholidayauth',
    list:   'user/list',
    listPaginate:   'user/listpaginate'
  },
  condition: {
    create:   'condition/create',
    update:   'condition/update/1'
  },
  conge: {
    acceptedRefused:   'conge/accepted/refused',
    updateStatus:   'conge/update/status/',
  },
  authorisation:{
    updateStatus:   'autorisation/update/status/'
  }
};

export { API_HOST, API, CONTENT };
