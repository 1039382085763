import React, { useEffect, useState } from 'react';
import { Provider, useSelector } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { ConfigProvider } from 'antd';
import Admin from './routes/admin';
import Auth from './routes/auth';
import ProtectedRoute from './components/utilities/protectedRoute';

import config from './config/config';
import { store } from './redux/store';
import { getTokenFromCookies, getValidationToken, isAdmin } from './services/authenticationService';

import './fontello/css/fontello.css';
import 'styles/globals.css';

const { theme } = config;

const ProviderConfig = () => {
  const { rtl, isLoggedIn, topMenu, darkMode } = useSelector(state => {
    return {
      darkMode: state.layout.data,
      rtl: state.layout.rtlData,
      topMenu: state.layout.topMenu,
      isLoggedIn: state.auth.login,
    };
  });

  const [path, setPath] = useState(window.location.pathname);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      setPath(window.location.pathname);
    }
    return () => (unmounted = true);
  }, [setPath]);

  return (
    <ConfigProvider direction={rtl ? 'rtl' : 'ltr'}>
      <ThemeProvider theme={{ ...theme, rtl, topMenu, darkMode }}>
        <Router basename={process.env.PUBLIC_URL}>
          {!isLoggedIn || !isAdmin() || getValidationToken() ? (
            <Route path="/" component={Auth} />
          ) : (
            <ProtectedRoute path="/admin" component={Admin} />
          )}
          {isLoggedIn &&
            (path === process.env.PUBLIC_URL || path === `${process.env.PUBLIC_URL}/`) &&
            getTokenFromCookies() && <Redirect to="/admin" />}
        </Router>
      </ThemeProvider>
    </ConfigProvider>
  );
};

function App() {
  return (
    <Provider store={store}>
      <ProviderConfig />
    </Provider>
  );
}

export default App;
