export const dataTable = [
  {
    key: '1',
    id: '1123',
    manager: 'John Doe',
    schoolName: 'Speedy',
    registrationDate: '15 - 08 - 2020',
    isActive: true,
  },
  {
    key: '2',
    id: '1578',
    manager: 'John Doe',
    schoolName: 'Norauto',
    registrationDate: '15 - 08 - 2020',
    isActive: false,
  },
  {
    key: '3',
    id: '1574',
    manager: 'John Doe',
    schoolName: 'Midas',
    registrationDate: '15 - 08 - 2020',
    isActive: false,
  },
  {
    key: '4',
    id: '5782',
    manager: 'John Doe',
    schoolName: 'EuroRepar',
    registrationDate: '15 - 08 - 2020',
    isActive: true,
  },
  {
    key: '5',
    id: '7829',
    manager: 'John Doe',
    schoolName: 'Feu Vert',
    registrationDate: '15 - 08 - 2020',
    isActive: true,
  },
  {
    key: '11',
    id: '1123',
    manager: 'John Doe',
    schoolName: 'Speedy',
    registrationDate: '15 - 08 - 2020',
    isActive: true,
  },
  {
    key: '21',
    id: '1578',
    manager: 'John Doe',
    schoolName: 'Norauto',
    registrationDate: '15 - 08 - 2020',
    isActive: false,
  },

  {
    key: '11',
    id: '1123',
    manager: 'John Doe',
    schoolName: 'Speedy',
    registrationDate: '15 - 08 - 2020',
    isActive: true,
  },
  {
    key: '22',
    id: '1578',
    manager: 'John Doe',
    schoolName: 'Norauto',
    registrationDate: '15 - 08 - 2020',
    isActive: false,
  },
  {
    key: '32',
    id: '1574',
    manager: 'John Doe',
    schoolName: 'Midas',
    registrationDate: '15 - 08 - 2020',
    isActive: false,
  },
  {
    key: '42',
    id: '5782',
    manager: 'John Doe',
    schoolName: 'EuroRepar',
    registrationDate: '15 - 08 - 2020',
    isActive: true,
  },
  {
    key: '52',
    id: '7829',
    manager: 'John Doe',
    schoolName: 'Feu Vert',
    registrationDate: '15 - 08 - 2020',
    isActive: true,
  },
  {
    key: '14',
    id: '1123',
    manager: 'John Doe',
    schoolName: 'Speedy',
    registrationDate: '15 - 08 - 2020',
    isActive: true,
  },
  {
    key: '24',
    id: '1578',
    manager: 'John Doe',
    schoolName: 'Norauto',
    registrationDate: '15 - 08 - 2020',
    isActive: false,
  },

  {
    key: '14',
    id: '1123',
    manager: 'John Doe',
    schoolName: 'Speedy',
    registrationDate: '15 - 08 - 2020',
    isActive: true,
  },
  {
    key: '24',
    id: '1578',
    manager: 'John Doe',
    schoolName: 'Norauto',
    registrationDate: '15 - 08 - 2020',
    isActive: false,
  },
  {
    key: '34',
    id: '1574',
    manager: 'John Doe',
    schoolName: 'Midas',
    registrationDate: '15 - 08 - 2020',
    isActive: false,
  },
  {
    key: '44',
    id: '5782',
    manager: 'John Doe',
    schoolName: 'EuroRepar',
    registrationDate: '15 - 08 - 2020',
    isActive: true,
  },
  {
    key: '54',
    id: '7829',
    manager: 'John Doe',
    schoolName: 'Feu Vert',
    registrationDate: '15 - 08 - 2020',
    isActive: true,
  },
  {
    key: '15',
    id: '1123',
    manager: 'John Doe',
    schoolName: 'Speedy',
    registrationDate: '15 - 08 - 2020',
    isActive: true,
  },
  {
    key: '25',
    id: '1578',
    manager: 'John Doe',
    schoolName: 'Norauto',
    registrationDate: '15 - 08 - 2020',
    isActive: false,
  },
];

export const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Gérant',
    dataIndex: 'manager',
    key: 'manager',
  },
  {
    title: 'Nom d’école',
    dataIndex: 'schoolName',
    key: 'schoolName',
  },
  {
    title: 'Date d’inscrit',
    dataIndex: 'registrationDate',
    key: 'registrationDate',
  },
  {
    title: 'Statut',
    dataIndex: 'isActive',
    key: 'isActive',
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
  },
];
