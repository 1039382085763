import { createAsyncThunk } from '@reduxjs/toolkit';
import { cashFlow } from '../../../demoData/dashboardChartContent.json';

export const cashFlowGetData = createAsyncThunk('chart/getCashFlowData', async () => {
  const { year } = cashFlow;

  try {
    return year;
  } catch (error) {
    return error;
  }
});

export const cashFlowFilterData = createAsyncThunk('chart/filterCashFlowData', async value => {
  try {
    return cashFlow[value];
  } catch (error) {
    return error;
  }
});

export const performanceGetData = createAsyncThunk('chart/getPerformanceData', async () => {
  const { year } = performance;

  try {
    return year;
  } catch (error) {
    return error;
  }
});

export const performanceFilterData = createAsyncThunk('chart/filterPerformanceData', async value => {
  try {
    return performance[value];
  } catch (error) {
    return error;
  }
});
