import { createSlice } from '@reduxjs/toolkit';
import { dataTable, columns } from './dataTable';

const initialState = {
  dataTable,
  columns,
  details: {
    isOpen: false,
    data: null,
  },
};

export const drivingSchoolSlice = createSlice({
  name: 'driving-school-list',
  initialState,
  reducers: {
    toggleSchoolDriveDetails: (state, action) => {
      state.details.isOpen = !state.details.isOpen;
      state.details.isOpen ? (state.details.data = action.payload) : (state.details.data = null);
    },
  },
});

export const { toggleSchoolDriveDetails } = drivingSchoolSlice.actions;

export default drivingSchoolSlice.reducer;
